import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translations
import en from "./locales/en.json";
import es from "./locales/es.json";

i18n
  .use(LanguageDetector) // Auto-detect language from browser settings
  .use(initReactI18next) // Initialize React i18next
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es }
    },
    fallbackLng: "en", // Default language
    interpolation: { escapeValue: false }, // React handles escaping
    detection: {
      order: ["localStorage", "navigator"], // Detect language from localStorage first
      caches: ["localStorage"], // Store language in localStorage
    },
  });

export default i18n;
