import "./i18n";
import React from "react";
import ReactDom from "react-dom";
import './index.css';
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from './util/theme';
import GlobalStyle from './GlobalStyle';

const Root = () => (
  <ThemeProvider>
    <GlobalStyle />
    <App />
  </ThemeProvider>
);

ReactDom.render(
  <Root />,
  document.getElementById("root")
);

serviceWorker.unregister();
