import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { ThemeProvider } from "./../util/theme";
import GlobalStyle from './../GlobalStyle';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
  },
  sectionTitle: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  question: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#000000',
  },
  answer: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 300,
    color: '#000000',
  },
}));

function HelpSection(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  
  const items = [{
    question: t("what_is_tipon"),
    answer: t("what_is_tipon_desc"),
  }, {
    question: t("how_does_tipon_work"),
    answer: t("how_does_tipon_work_desc"),
  }, {
    question: t("industries_benefit"),
    answer: t("industries_benefit_desc"),
  }, {
    question: t("secure_transactions"),
    answer: t("secure_transactions_desc"),
  }, {
    question: t("getting_started"),
    answer: t("getting_started_desc"),
  }, {
    question: t("benefits_employees_businesses"),
    answer: t("benefits_employees_businesses_desc"),
  },
  ];

  return (
  <>
    <GlobalStyle />
    <ThemeProvider>
    <Section id="help-section" 
      bgColor={"white"}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Box textAlign="center">
          <Typography className={classes.sectionTitle}>{t("faq")}</Typography>
        </Box>
        <Box pt={2}>
          {items.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-panel-${index}`}
              >
                <Typography className={classes.question}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails id={`faq-panel-${index}`}>
                <Typography className={classes.answer}>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Section>
    </ThemeProvider>
  </>
  );
}

export default HelpSection;
