import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
  },
  sectionHeader: {
    backgroundColor: 'white',
    marginBottom: theme.spacing(6),
  },
  sectionTitle: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  featureImage: {
    backgroundColor: 'white',
    width: '100px', // Adjust size as needed
    height: '100px',
  },
  title1: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.8rem',
    fontWeight: 500,
    color: '#8A58E0',
    marginBottom: theme.spacing(3),
  },
  text: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 300,
    color: '#000000',
    marginBottom: theme.spacing(0),
  },
}));

function BenefitsSection(props) {
  const classes = useStyles();
    const { t } = useTranslation();

  const items = [
    {
      title: t("increased_earnings"),
      subtitle: t("increased_earnings_desc"),
      image: "/increased.png",
    },
    {
      title: t("convenience_usability"),
      subtitle: t("convenience_usability_desc"),
      image: "/convenience.png",
    },
    {
      title: t("feedback"),
      subtitle: t("feedback_desc"),
      image: "/realtime.png",
    },
    {
      title: t("reliability_security"),
      subtitle: t("reliability_security_desc"),
      image: "/security.png",
    },
  ];

  return (
    <Section id="benefits-section" 
      bgColor={"white"}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Box textAlign="center">
          <Typography className={classes.sectionTitle}>{t("key_benefits")}</Typography>
        </Box>
        <Grid container={true} justifyContent="center" spacing={6}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={3} key={index}>
              <Box textAlign="center">
                <Box>
                  <img src={item.image} alt={item.title} className={classes.featureImage} />
                </Box>
                <Typography variant="h5" gutterBottom={true} className={classes.title1}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1" className={classes.text}>{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default BenefitsSection;
