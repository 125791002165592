import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(3)}px`,
    backgroundColor: 'white',
  },
  title: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  featureImage: {
    width: '300px',
    height: '300px',
  },
  title1: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.5rem',
    fontWeight: 500,
    color: '#000000',
    marginBottom: theme.spacing(1),
  },
  title2: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 500,
    color: '#8A58E0',
    marginBottom: theme.spacing(3),
  },
  text: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 300,
    color: '#000000',
    marginBottom: theme.spacing(0),
  },
}));

function TeamSection(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const items = [
    {
      title1: "Sergey Sigitov",
      title2: "CEO",
      image: "/sergey.png",
    },
    {
      title1: "Roman Pelevin",
      title2: "CTO",
      image: "/roman.png",
    },
  ];

  return (
    <Section id="features-section" 
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Box textAlign="center" className={classes.sectionHeader}>
          <Typography variant="h2" className={classes.title}>{t("tipon_team")}</Typography>
        </Box>
        <Grid container={true} justifyContent="center" spacing={6}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={5} key={index}>
              <Box textAlign="center">
                <Box>
                  <img src={item.image} alt={item.title} className={classes.featureImage} />
                </Box>
                <Typography variant="h5" gutterBottom={true} className={classes.title1}>
                  {item.title1}
                </Typography>
                <Typography variant="h5" gutterBottom={true} className={classes.title2}>
                  {item.title2}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default TeamSection;
