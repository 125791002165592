import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(3)}px`,
    backgroundColor: 'white',
  },
  title: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  featureImage: {
    width: '100px',
    height: '100px',
  },
  title1: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.8rem',
    fontWeight: 500,
    color: '#8A58E0',
    marginBottom: theme.spacing(3),
  },
  text: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 300,
    color: '#000000',
    marginBottom: theme.spacing(0),
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const items = [
    {
      title: t("seamless_payments"),
      subtitle: t("seamless_payments_desc"),
      image: "/seamless.png",
    },
    {
      title: t("real_time_feedback"),
      subtitle: t("real_time_feedback_desc"),
      image: "/realtime.png",
    },
    {
      title: t("global_accessibility"),
      subtitle: t("global_accessibility_desc"),
      image: "/global.png",
    },
    {
      title: t("user_friendly_interface"),
      subtitle: t("user_friendly_interface_desc"),
      image: "/user.png",
    },
  ];

  return (
    <Section id="features-section" 
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Box textAlign="center" className={classes.sectionHeader}>
          <Typography variant="h2" className={classes.title}>{t("why_choose_tipon")}</Typography>
        </Box>
        <Grid container={true} justifyContent="center" spacing={6}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={3} key={index}>
              <Box textAlign="center">
                <Box>
                  <img src={item.image} alt={item.title} className={classes.featureImage} />
                </Box>
                <Typography variant="h5" gutterBottom={true} className={classes.title1}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1" className={classes.text}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
