import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    paddingTop: `69px`,
    textAlign: 'center',
  },
  sectionContent: {
    backgroundColor: 'white',
    borderRadius: '9px',
  },
  button: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 300,
    border: '1px solid #ccc',
    borderRadius: '9px',  // Updated to match the desired rounded corners
    backgroundColor: 'white',
    color: '#333',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#f9f9f9',
      borderColor: '#bbb',
    },
  },
}));

const TopSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
      <Container className={classes.container}>
        <Box className={classes.sectionContent}>
          <Button className={classes.button} onClick={handleScrollToTop}>
            {t("back_to_top")}
          </Button>
        </Box>
      </Container>
  );
};

export default TopSection;
