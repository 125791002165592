import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { scroller } from "react-scroll";
import Section from "./Section";
import { useDarkMode } from "./../util/theme";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
    cursor: "pointer",
  },
  drawerList: {
    backgroundColor: "white",
    width: 240, // Ensures proper width
    padding: "8px 0", // Adds spacing
  },
  menuItemText: {
    fontFamily: "Onest, sans-serif",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: "#695DFF", // Restoring original color
    textDecoration: "none",
    textTransform: "none",
    "&:hover": {
      color: "darkblue",
    },
  },  
  spacer: {
    backgroundColor: "white",
    flexGrow: 1,
  },
  headerLink: {
    fontFamily: "Onest, sans-serif",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: "#695DFF",
    textDecoration: "none",
    margin: theme.spacing(1),
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      color: "darkblue",
    },
  },
  connectButton: {
    fontFamily: "Onest, sans-serif",
    fontSize: "0.9rem",
    fontWeight: 600,
    backgroundColor: "#695DFF",
    color: "white",
    padding: theme.spacing(1, 3),
    textTransform: "none",
    "&:hover": {
      backgroundColor: "darkblue",
    },
  },
  appBar: {
    backgroundColor: "white",
    height: 87,
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  menuItem: {
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.04)",
    },
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const darkMode = useDarkMode();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const logo = props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  useEffect(() => {
    const handleHashChange = () => {
      const sectionId = window.location.hash.replace("#", "");
      const offset = -87;
      if (sectionId) {
        setTimeout(() => {
          scroller.scrollTo(sectionId, {
            smooth: true,
            duration: 500,
            offset: offset,
          });
        }, 100);
      }
    };

    window.addEventListener("hashchange", handleHashChange, false);
    window.addEventListener("load", handleHashChange, false);

    if (window.location.hash) {
      handleHashChange();
    }

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
      window.removeEventListener("load", handleHashChange, false);
    };
  }, []);

  const handleLinkClick = (sectionId) => {
    const offset = -87;
    if (location.pathname !== "/") {
      window.location.href = `/#${sectionId}`;
    } else {
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 500,
        offset: offset,
      });
    }
    setDrawerOpen(false);
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname !== "/") {
      window.location.href = "/#hero2-section";
    } else {
      scroller.scrollTo("hero2-section", {
        smooth: true,
        duration: 500,
        offset: -87,
      });
    }
  };

  // Language Switcher Logic
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="sticky" elevation={0} className={classes.appBar}>
        <Container disableGutters={true}>
          <Toolbar className={classes.toolbar}>
            <RouterLink to="/" className={classes.headerLink} onClick={handleLogoClick}>
              <img src={logo} alt="TIPON" className={classes.logo} />
            </RouterLink>
            <div className={classes.spacer} />
            
            {/* Mobile Menu Icon */}
            <Hidden mdUp>
              <IconButton onClick={() => setDrawerOpen(true)} color="primary">
                <MenuIcon />
              </IconButton>
            </Hidden>

            {/* Desktop Navigation */}
            <Hidden smDown>
            {/* Language Switcher - Positioned before "Home" */}
            <Button onClick={handleOpen} className={classes.languageButton}>
              <span style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <span style={{ fontSize: "1rem", display: "inline-block" }}>
                  {i18n.language === "es" ? "🇪🇸" : "🇺🇸"}
                </span>
                <span>{i18n.language === "es" ? "Español" : "English"}</span>
                <span style={{ fontSize: "0.9rem", opacity: "0.7" }}>▾</span>
              </span>
            </Button>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} keepMounted>
              <MenuItem onClick={() => handleChangeLanguage("en")}>🇺🇸 English</MenuItem>
              <MenuItem onClick={() => handleChangeLanguage("es")}>🇪🇸 Español</MenuItem>
            </Menu>

            {/* 🔥 RESTORED MENU ITEMS 🔥 */}
            <Button onClick={() => handleLinkClick("hero2-section")} className={classes.headerLink}>
              {t("home")}
            </Button>
            <Button onClick={() => handleLinkClick("features-section")} className={classes.headerLink}>
              {t("features")}
            </Button>
            <Button onClick={() => handleLinkClick("how-section")} className={classes.headerLink}>
              {t("how_it_works")}
            </Button>
            <Button onClick={() => handleLinkClick("benefits-section")} className={classes.headerLink}>
              {t("benefits")}
            </Button>
            <Button onClick={() => handleLinkClick("help-section")} className={classes.headerLink}>
              {t("help")}
            </Button>
            
            {/* Connect Button */}
            <Box component="span" ml={1}>
              <Button onClick={() => handleLinkClick("connect-section")} variant="contained" className={classes.connectButton}>
                {t("connect")}
              </Button>
            </Box>
          </Hidden>

          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Drawer Menu */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List className={classes.drawerList}>

          {/* Language Switcher Inside Drawer */}
          <ListItem button onClick={handleOpen}>
            <Box display="flex" alignItems="center" width="100%">
              <span style={{ fontSize: "1rem", marginRight: "6px" }}>
                {i18n.language === "es" ? "🇪🇸" : "🇺🇸"}
              </span>
              <span style={{ fontWeight: 600, flexGrow: 1 }}>
                {i18n.language === "es" ? "Español" : "English"}
              </span>
              <span style={{ fontSize: "0.9rem", opacity: "0.7" }}>▾</span>

            </Box>
          </ListItem>

          {/* Language Dropdown is now fixed inside the menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            keepMounted
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Keep dropdown inside drawer
            transformOrigin={{ vertical: "top", horizontal: "center" }} // Prevents misalignment
            PaperProps={{
              style: {
                width: "180px", // Ensures proper dropdown width
                marginTop: "8px", // Adds spacing below language switcher
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 4px 12px", // Soft shadow
                borderRadius: "8px",
              },
            }}
          >
            <MenuItem onClick={() => handleChangeLanguage("en")}>
              🇺🇸 <span style={{ marginLeft: "6px" }}>English</span>
            </MenuItem>
            <MenuItem onClick={() => handleChangeLanguage("es")}>
              🇪🇸 <span style={{ marginLeft: "6px" }}>Español</span>
            </MenuItem>
          </Menu>

          {/* Full Navigation Menu */}
          <ListItem button onClick={() => handleLinkClick("hero2-section")}>
            <ListItemText primary={t("home")} classes={{ primary: classes.menuItemText }} />
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("features-section")}>
            <ListItemText primary={t("features")} classes={{ primary: classes.menuItemText }} />
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("how-section")}>
            <ListItemText primary={t("how_it_works")} classes={{ primary: classes.menuItemText }} />
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("benefits-section")}>
            <ListItemText primary={t("benefits")} classes={{ primary: classes.menuItemText }} />
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("help-section")}>
            <ListItemText primary={t("help")} classes={{ primary: classes.menuItemText }} />
          </ListItem>

          {/* "Connect" Button */}
          <ListItem button onClick={() => handleLinkClick("connect-section")}>
            <Button variant="contained" className={classes.connectButton} fullWidth>
              {t("connect")}
            </Button>
          </ListItem>

        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
