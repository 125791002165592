import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import emailjs from 'emailjs-com';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
    textAlign: 'center',
  },
  sectionContent: {
    backgroundColor: 'white',
    background: 'white',
    borderRadius: '9px',
    padding: '42px',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)',
    border: '0.3px solid #eee',
  },
  title: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#000000',
    margin: '0 0 10px',
  },
  subtitle: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: '#000000',
    margin: '0 0 36px',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  selectField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 600,
    backgroundColor: '#695DFF',
    color: 'white',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
    padding: theme.spacing(1, 3),
    textTransform: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  radioGroup: {
    display: 'block',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  footerText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontSize: '0.875rem',
    opacity: 0.6,
  },
  link: {
    color: 'inherit',
    fontWeight: 600,
  },
  successMessage: {
    color: 'green',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  errorMessage: {
    color: 'red',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
}));

function ContactSection(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    businessName: '',
    address: '',
    name: '',
    phone: '',
    serviceType: '',
    position: 'business'
  });

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    emailjs.send('service_etbriqm', 'template_gc17wzt', formData, 'pkE15n68-WVz9x96W')
      .then((result) => {
        console.log('Form successfully submitted', result.text);
        setFormAlert({
          type: "success",
          message: "Your application has been successfully submitted!"
        });
        setPending(false);
      }, (error) => {
        console.error('There was an error!', error.text);
        setFormAlert({
          type: "error",
          message: "There was an error submitting your application. Please try again."
        });
        setPending(false);
      });
  };

  return (
    <Section id="connect-section">
      <Container className={classes.container}>
        <Box className={classes.sectionContent}>
          <Typography variant="h4" className={classes.title}>
            {t("connect_business")}
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            {t("connect_business_desc")}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("business_name")}
                  placeholder="Ex. Unimag Store"
                  variant="outlined"
                  fullWidth
                  required
                  className={classes.textField}
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("address")}
                  placeholder="Ex. Melang Str, 24, of.250"
                  variant="outlined"
                  fullWidth
                  required
                  className={classes.textField}
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("your_name")}
                  placeholder="Ex. John Brown"
                  variant="outlined"
                  fullWidth
                  required
                  className={classes.textField}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("contact_phone")}
                  placeholder="Ex. +1 657 888 887"
                  variant="outlined"
                  fullWidth
                  required
                  className={classes.textField}
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" className={classes.radioGroup}>
                  <RadioGroup aria-label="position" name="position" value={formData.position} onChange={handleChange}>
                    <FormControlLabel
                      value="business"
                      control={<Radio color="primary" />}
                      label={t("business_owner_option")}
                    />
                    <FormControlLabel
                      value="employee"
                      control={<Radio color="primary" />}
                      label={t("employee_option")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  disabled={pending}
                >
                  {pending ? 'Submitting...' : t("submit_application")}
                </Button>
              </Grid>
            </Grid>
          </form>
          {formAlert && (
            <Typography variant="body2" className={formAlert.type === "success" ? classes.successMessage : classes.errorMessage}>
              {formAlert.message}
            </Typography>
          )}
        </Box>
      </Container>
    </Section>
  );
}

export default ContactSection;
