import React from "react";
import Meta from "./../components/Meta";
import Hero2Section from "./../components/Hero2Section";
import FeaturesSection from "./../components/FeaturesSection";
import TeamSection from "./../components/TeamSection";
import HowSection from "./../components/HowSection";
import BenefitsSection from "./../components/BenefitsSection";
import CtaSection from "./../components/CtaSection";
import TopSection from "./../components/TopSection";
import ContactSection from "./../components/ContactSection";
import InfoSection from "./../components/InfoSection";
import HelpSection from "./../components/HelpSection";
import { useTranslation } from "react-i18next";

function IndexPage(props) {
  const { t } = useTranslation();

  return (
    <>
      <Meta />
      <Hero2Section
        bgColor="white"
        size="large"
        bgImage="/hero2.png"
        bgImageOpacity={0.3}
        title="QR and NFC Tipping"
        subtitle="Customers can scan or tap to pay using their preferred method!"
        buttonText="Connect your business"
        buttonColor="default"
        buttonPath="/pricing"
      />
      <FeaturesSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <HowSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title=" How Tipon Works"
        subtitle=""
      />
      <BenefitsSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <CtaSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <ContactSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
      <TeamSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <InfoSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <HelpSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
      <TopSection
        bgColor="white"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle="Or please get started now even if you don't feel ready. We're just really going to need you to get started. Click it. Click the button."
        buttonText={t("back_to_top")}
        buttonColor="primary"
        buttonPath="/pricing"
      />
    </>
  );
}

export default IndexPage;
